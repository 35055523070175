import { get } from 'lodash-es'
import apiPaths from '@/constants/apiPaths'
import trackMountainConversion from '@/scripts/mountain.js'
import { getUtmParams, getLocalStorageData, setLocalStorageData } from '@/utils'
import { MOUNTAIN_ENABLED } from '@/constants/featureFlags'

const STORAGE_KEY = 'bungalow-website'

const MOVING_TIMEFRAMES = {
  1: 'As soon as possible',
  30: '0 – 30 days',
  60: '30 – 60 days',
  0: 'Just looking',
}

const getDefaultState = () => ({
  data: getLocalStorageData(STORAGE_KEY),
  // NOTE: The flow is dynamic, so updating this dictates the order:
  ungatedRoomsFunnel: ['MemberFunnelPreferences', 'MemberFunnelProfile', 'MemberFunnelOutcome'],
  ungatedHomesFunnel: [
    'MemberFunnelProfile',
    'MemberFunnelGroup',
    'MemberFunnelLease',
    'MemberFunnelOutcome',
  ],
  ungatedInstantFunnel: ['MemberFunnelProfile', 'MemberFunnelOutcome'],
  gatedRoomsFunnel: [
    'MemberFunnelStart',
    'MemberFunnelProfile',
    'MemberFunnelPreferences',
    'MemberFunnelIncome',
    'MemberFunnelBackground',
    'MemberFunnelOutcome',
  ],
  gatedHomesFunnel: [
    'MemberFunnelStart',
    'MemberFunnelProfile',
    'MemberFunnelGroup',
    'MemberFunnelLease',
    'MemberFunnelIncome',
    'MemberFunnelBackground',
    'MemberFunnelOutcome',
  ],
  activeBackgroundFlags: [
    'has_been_evicted_in_past',
    // Question Disabled – Q4 2022
    // 'has_broken_lease_in_past',
    'has_been_sued_for_damages_to_rental',
    // 'has_filed_for_bankruptcy_in_past_7_years',
    'has_been_convicted',
    'has_been_sued_for_rent_payments',
  ],
})

const state = getDefaultState()

const actions = {
  setFunnelData({ commit }, payload) {
    setLocalStorageData(STORAGE_KEY, payload)
    commit('setData', payload)
  },
  // Request application source and navigate to apply flow:
  async openNewApplication(
    { getters },
    { property, handlers = {}, queryParams, promo = 'showSplash' }
  ) {
    let { email, firstName, lastName, phone } = getters.funnelData
    const utmParams = getUtmParams(queryParams)
    const userEmail = email && email.toLowerCase()
    const currentUrl = window.location.href.split('?')[0]

    try {
      // Generate application source...
      const source = await this._vm.$http.post('applications/source', {
        email: userEmail,
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        market: property.market.displayName,
        property: `${property.id}`,
        extra: {
          name: `${firstName} ${lastName}`,
          lead_source: utmParams.utm_source,
          property_url: currentUrl,
        },
      })

      // Track Event
      handlers.onSuccess && handlers.onSuccess(source.data)

      // Add Promo Flag
      if (promo) source.data.url += `&promo=${promo}${property._isGroupHome ? '&isGroup=true' : ''}`

      // Open Application
      window.location = source.data.url
    } catch (error) {
      // Log Error
      console.error(error)

      // Track Error
      this._vm.$sentry.captureException(error)
      handlers.onError && handlers.onError(error)
    }
  },
  // Create a demand lead in SFDC
  async createOrUpdateDemandLead(
    { dispatch, rootGetters },
    {
      lead = {},
      query = {},
      property = {},
      intentLevel = 'property',
      marketDisplayName = '',
      homeType = '',
      captureInterest = true,
    }
  ) {
    try {
      // Ensure we grab any relevant UTM params from the URL:
      const utmParams = getUtmParams(query)

      try {
        const cookies = document.cookie
          .split(';')
          .map((cookie) => cookie.split('='))
          .reduce(
            (cookieItems, [key, value]) => ({
              ...cookieItems,
              [key.trim()]: decodeURIComponent(value),
            }),
            {}
          )

        // Facebook in-app/browser doesnt set fbclid in URL, this pulls it from cookies:
        const fbclick = cookies._fbc
        if (fbclick && !utmParams['fbclid']) utmParams['fbclid'] = fbclick.slice(19)

        // Pull out GCLID that we previously stashed in cookies
        const gclid = cookies.gclid
        if (gclid && !utmParams['gclid']) utmParams['gclid'] = gclid
      } catch (error) {
        this._vm.$sentry.captureMessage('Error Extracting Click IDs', {
          extra: { error },
        })
      }

      // We need the property's SFID, so perform a special request to fieldstone if missing:
      const propertySFID =
        property?.sfid ||
        (await dispatch('property/fetchPropertySFID', property?.slug, { root: true }))

      // Extrapolate market name from property:
      const marketName = marketDisplayName
        ? marketDisplayName
        : property?.market?.displayName || undefined

      const group_type_options = ['family', 'agent', 'roommates', 'individual']

      const group_type = group_type_options.includes(query.group_type)
        ? query.group_type
        : undefined

      // Create a full name for the user from first + last:
      const firstName = lead.firstName || lead.first_name
      const lastName = lead.lastName || lead.last_name
      const fullName = [firstName, lastName].join(' ').trim()
      const isGroupHome = homeType === 'group_living' || property._isGroupHome
      const livingSituation = isGroupHome ? 'groups' : 'coliving'

      // Compose payload for lead creation via Segment:
      const leadData = {
        origin: 'website',
        created_at: new Date(),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        email: lead.email,
        name: fullName,
        first_name: firstName,
        last_name: lastName,
        phone: lead.phone,
        market: marketName,
        record_type: 'Demand',
        property: propertySFID || undefined,
        moving_timeframe_date: lead.moving_timeframe_date,
        preferred_method_of_contact: lead.contactMethod,
        pets: lead.pets,
        living_situation: livingSituation,
        group_type,
        Group_Source_Slug: lead.groupSource,
        ...(lead.referral_source !== undefined ? { referral_source: lead.referral_source } : {}),
        ...utmParams,
      }

      // Send lead to SFDC, via Segment:
      if (lead.email) {
        this._vm.$segment.identify(lead.email, leadData)

        if (captureInterest) {
          const interestCapturePayload = {
            origin: 'website',
            email: lead.email,
            market: marketName,
            intent_level: intentLevel,
            is_b2b_home: intentLevel === 'property' && !property.retailProperty,
            property_sfid: propertySFID,
            property_id: property?.id || undefined,
            living_situation: livingSituation,
            ...utmParams,
          }

          // Segment Event
          this._vm.$segment.track('Interest Captured', interestCapturePayload)

          // Google Event
          try {
            window?.gtag('event', 'Interest Captured', interestCapturePayload)
          } catch (googleError) {}
        }
      }
      // Save GCLID to user profile
      if (utmParams.gclid) dispatch('saveGCLID', utmParams.gclid)
    } catch (leadCaptureError) {
      this._vm.$sentry.captureException(leadCaptureError)
    }

    // Track Mountain Conversions (Affiliate Marketing Program)
    try {
      const userId = rootGetters['user/activeUser']?.id
      const flags = rootGetters['flags/featureFlags']
      const mountainEnabled = flags.includes(MOUNTAIN_ENABLED)

      if (mountainEnabled && userId) trackMountainConversion(userId)
    } catch (mountainError) {
      this._vm.$sentry.captureException(mountainError)
    }
  },
  async saveGCLID({ rootGetters }, gclidFromUtmParams) {
    if (!gclidFromUtmParams) return
    const userId = rootGetters['user/activeUser']?.id
    const existingGCLID = rootGetters['user/applicantProfile']?.googleClickId

    if (!userId || existingGCLID) return

    return this._vm.$http.post(apiPaths.applications.applicantProfile, {
      google_click_id: gclidFromUtmParams,
    })
  },
}

const mutations = {
  setData(state, payload) {
    // Merge with existing data, setting is done per-key:
    state.data = { ...state.data, ...payload }
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const getters = {
  activeBackgroundFlags: (state) => state.activeBackgroundFlags || [],
  movingTimeframes: () => MOVING_TIMEFRAMES,
  funnelData: (state) => state.data || {},
  hasBeenWelcomed: (state, getters) => !!getters.funnelData.welcomed,
  meetsPropertyRequirements: (state, getters) => (property) => {
    // A property is required to do the check
    if (!property) return false

    // Extract relevant user preferences
    const { masterBedroomRequired, movesInWithPet, requiresExtraBedroom } = getters.funnelData

    // See if there's an extra bedroom available
    const extraBedroomAvailable = property.availableRoomCount > 1

    // Compare preferences to the property...
    if (
      (masterBedroomRequired && !property.masterBedroomAvailable) ||
      (movesInWithPet && !property.isPetFriendly) ||
      (requiresExtraBedroom && !extraBedroomAvailable)
    ) {
      return false // Property not compatible! ❌
    } else {
      return true // Property is a fit! ✅
    }
  },
  getMemberFunnelStep:
    (
      {
        ungatedRoomsFunnel,
        ungatedHomesFunnel,
        gatedRoomsFunnel,
        gatedHomesFunnel,
        ungatedInstantFunnel,
      },
      _getters,
      rootGetters
    ) =>
    (currentStep, gatedFunnel = true) => {
      const isHomesProperty = get(rootGetters, 'property.property._isGroupHome')
      const isInstantLease = get(rootGetters, 'property.property.isInstantLease')

      const homesFunnelSteps = gatedFunnel ? gatedHomesFunnel : ungatedHomesFunnel
      const roomsFunnelSteps = gatedFunnel
        ? // Gated funnel uses standard flow...
          gatedRoomsFunnel
        : // Not gated? We have an instant and non-instant version:
        isInstantLease
        ? ungatedInstantFunnel
        : ungatedRoomsFunnel
      const memberFunnelSteps = isHomesProperty ? homesFunnelSteps : roomsFunnelSteps

      // if (!currentStep) currentStep = memberFunnelSteps[0]

      if (gatedFunnel)
        // If we're ungated use those instead!
        gatedFunnel === false
          ? ungatedRoomsFunnel
          : // If we are gated, just use the defaults for Rooms/Homes:
          isHomesProperty
          ? gatedHomesFunnel
          : gatedRoomsFunnel

      const currentStepIndex = memberFunnelSteps.indexOf(currentStep)
      const nextStep = memberFunnelSteps[currentStepIndex + 1] || [...memberFunnelSteps].pop()
      const stepIndex = memberFunnelSteps.indexOf(currentStep) + 1

      return {
        nextRoute: nextStep,
        stepNumber: stepIndex,
        totalSteps: memberFunnelSteps.length,
        stepsLeft: memberFunnelSteps.length - stepIndex,
      }
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
